import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './components/Home';
import Contact from './components/contact/Contact';
class Router extends Component {

	render() {
		
// You can think of these components as "pages"
// in your app.

  
  function About() {
	return (
	  <div>
		<h2>About</h2>
	  </div>
	);
  }
  
  function Dashboard() {
	return (
	  <div>
		<h2>Dashboard</h2>
	  </div>
	);
	
  }
		return (
			<div>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/about">
						<About />
					</Route>
					<Route path="/dashboard">
						<Dashboard />
					</Route>
					<Route path="/contact">
						<Contact />
					</Route>
				</Switch>
			</div>
		)
	}
	
}

export default Router;